import PropTypes from "prop-types"
import React from "react"

import "./TeamGrid.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import Grid from "@material-ui/core/Grid"
import TeamItem from "../../components/team-item"
import { getImage } from "../../helper"

const TeamGrid = ({ title, description, members }) => {
  return (
    <Section className="TeamGrid Section--vPadding">
      <SectionInner className="TeamGrid-inner">
        <h2 className="Section-titleNotResponsive t-h2">{title}</h2>
        <div className="Section-text t-body2" dangerouslySetInnerHTML={{__html: description}} />
        <Grid className="TeamGrid-grid" container justify="center" spacing={10}>
          {
            members.map((memberItem, index) => {
                const data = {
                  ...memberItem,
                  image: getImage(memberItem.image, true, 'u-elevation-1 TeamGrid-image u-marginCenter', 'TeamGrid-image u-marginCenter', true),
                }
                return (
                  <Grid key={index} item xs={12} lg={6} xl={4}>
                    <TeamItem {...data} />
                  </Grid>
                )
              },
            )
          }
        </Grid>
      </SectionInner>
    </Section>
  )
}

TeamGrid.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default TeamGrid
