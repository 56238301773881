import PropTypes from "prop-types"
import React from "react"

import "./team-item.scss"
import {Launch} from "@material-ui/icons"

const TeamItem = ({ name, link, image }) => {
  return (
    <div className="TeamItem">
      {image}

      {
        link ?
          <a href={link} className="c-light-primary u-linkHover u-noWrap u-flex TeamItem-name">
            <h3 className="t-h3">{name}</h3> <Launch className="u-marginLeft2" />
          </a>
          :
          <h3 className="TeamItem-name t-h3">{name}</h3>

      }


    </div>
  )
}

TeamItem.propTypes = {
  name: PropTypes.string,
  nickname: PropTypes.string,
  image: PropTypes.object,
}

export default TeamItem
